.modal-metadata-header {
  &_col {
    border: 1px dashed #f0f0f0;
  }

  &_row {
    padding-bottom: 8px;
  }

  &_card {
    background: #fafafa !important;

    &-content {
      display: flex;
      justify-content: space-between;

      & .item {
        &_name {
          color: #262626;
          font-weight: bold;
        }

        &_value {
          font-size: 15px;
          color: #262626;
        }
      }
    }
  }
}

.table-modal {
  .ant-modal-content {
    height: calc(100vh - 30px);
  }
}

@import 'variables';

.input {
  &_number {
    width: 100% !important;
  }
}

.centered_input{
  text-align: center !important;
}

.table_input {
  margin: 2px !important;
}

.highlighted_positive {
  border: 1px solid $success !important;
}

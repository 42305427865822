@import 'variables';

.layout_wrapper {
  background-color: $mainBackground;
}

.layout_wrapper_sider.ant-layout-sider.ant-layout-sider-dark.sideBar {
  background-color: $mainBackground;
  height: 100vh;
  overflow: auto;
}

.layout_header.ant-layout-header {
  padding: 0;
  display: flex;
  background-color: $mainBackground;
}

.layout_header_wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.layout_header_wrapper_parent_collapsed_button {
  display: flex;
  align-items: center;
}

.layout_header_wrapper_collapsed_button.anticon svg {
  color: $whiteColor;
  font-size: $iconSize;
}

.layout_app_nav_bar_wrapper {
  height: 100%;
}

.layout_content {
  margin: 0 5px 0;
  overflow: hidden;
}

.menu_item {
  color: white !important;
}

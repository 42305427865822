.purchase_details {
  .ant-table-footer {
    width: 100%;
    .table_footer {
      display: flex;
      justify-content: space-between;
      font-weight: bold;
    }
  }

  .content_wrapper {
    text-align: right;
  }
  .title_list {
    min-width: 120px;
  }

  .products_table {
    .ant-table {
      height: 85vh;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .ant-table-body {
        max-height: calc(100vh - 16em) !important;
      }
    }
  }
}

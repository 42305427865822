@import 'variables';

.raw_form{
    .ant-form-item {
      margin-bottom: 8px !important;
    }

}

.button {
  display: flex;
  justify-content: flex-end;

}

.button_wrapper_action {
  padding-top: 10px;
  width: 100%;
}

.button_save.ant-btn {
  color: $save;
}

.input {
  width: 100%;
}

@import 'variables';

.deleteButton_wrapper{
  .delete_button{
    text-transform: uppercase !important;
    width: 100% !important;
  }
  :disabled{
   text-transform: uppercase !important;
    width: 100%;
  }
}

.columns {
  &.name {
    width: 300px;
  }

  &.amount {
    width: 85px;
  }

  &.selling_price {
    width: 90px;
  }

  &.selling_sum {
    width: 100px;
  }

  &.producing_price {
    width: 110px;
  }

  &.producing_sum {
    width: 100px;
  }
}

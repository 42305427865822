$save: #2e7d32;
$add: #3f50b5;
$download: #000080;
$danger: #ff4500;
$warning: #ffcccc;
$error: #FFC318;
$success: #49AA19;
$expenses: #CF1322;
$mainBackground: #3F50B5;
$mainColor: #3F50B5;
$greenColor: #2fa82d;
$grayColor: #F0F0F0;

$antdPrimaryColor: #1890FF;

$whiteColor: #fff;
$lightGray: #F0F0F0;
$darkGrayColor: #A9A9A9;
$iconSize: '20px';

$screenXS: '320px';
$screenSM: '375px';
$screenMD: '768px';
$screenLG: '1024px';
$screenXL: '1440px';
$screenXXL: '1600px';


.arrivalEmptyTable .ant-table .ant-empty-normal {
  height: 20vh;
}

.arrivalTableHeight .ant-table {
  //border:1px solid red;
  height: 40vh;
}

.ArrivalTable .ant-table {
  position: relative;
  font-size: 14px;
  height: 68vh;
}

.arrivalAddTable .ant-table-cell > p {
  padding-left: 12px;
  padding-top: 5px;
}

.ant-tooltip-inner {
  min-width: 30px;
  min-height: 32px;
  padding: 6px 8px;
  color: #fff;
  text-align: left;
  text-decoration: none;
  word-wrap: break-word;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 2px;
}

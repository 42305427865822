.planning .ant-modal-body {
  overflow: auto;
  height: calc(100vh - 6em);
  width: 100%;
}

.modal.ant-modal {
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  pointer-events: none;
  position: relative;
  top: 5px;
  width: 100vw;
  max-width: calc(100vw - 32px);
  margin: 0 auto;
  padding: 0 0 24px;
}

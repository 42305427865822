@import 'variables';

.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.logo {
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 16px;
  font-size: 20px;
  background: $mainBackground;
}

.sideBar {
  .ant-menu-light.ant-menu-inline,
  .ant-menu-light.ant-menu-vertical,
  .ant-menu-light.ant-menu-vertical-left,
  .ant-menu-light.ant-menu-vertical-right {
    background-color: $mainBackground;
    overflow: hidden;
  }

  .ant-menu-light .ant-menu-inline.ant-menu-sub {
    background-color: $mainBackground;
  }

  .ant-layout-sider-children .ant-menu.ant-menu-inline-collapsed {
    background-color: $mainBackground;
  }

  .ant-menu-inline.ant-menu-root .ant-menu-item > .ant-menu-title-content,
  .ant-menu-inline.ant-menu-root
    .ant-menu-submenu-title
    > .ant-menu-title-content {
    flex: auto;
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $whiteColor;
  }

  .anticon svg {
    display: inline-block;
    color: $whiteColor;
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #1890ff;
  }

  .ant-layout-sider-children {
    height: 100%;
    margin-top: -0.1px;
    padding-top: 0.1px;
    overflow: auto;
  }
}

.header {
  height: 100%;
}

.header_menu_style.ant-menu-dark.ant-menu-sub,
.ant-menu.ant-menu-dark,
.ant-menu.ant-menu-dark.ant-menu-sub {
  background-color: $mainBackground !important;
}

.header_menu_style_hover.ant-menu-dark.ant-menu-horizontal
  > .ant-menu-item:hover {
  background-color: unset;
}

.header_menu_style_selected.ant-menu-submenu-popup.ant-menu-dark.ant-menu-item-selected.ant-menu.ant-menu-dark.ant-menu-item-selected {
  background-color: unset;
}

.header_menu_style_selected_parent
  .ant-menu-submenu-popup.ant-menu-dark
  .ant-menu-item-selected,
.ant-menu.ant-menu-dark .ant-menu-item-selected {
  background-color: unset !important;
}

.header_config_row_parent_block {
  height: 100%;
}

.header_config_row_parent_block_config_style {
  flex-direction: column;
  line-height: 0;
  color: $darkGrayColor;
  font-style: italic;
  font-size: 10px;
  display: flex;
}

.icon_style {
  color: $whiteColor !important;
  font-size: 20px !important;
}

.header_dropdown_overlay {
  min-width: 250px !important;
}

.header_dropdown_children {
  cursor: pointer;
  align-items: center;
  color: $whiteColor;
}

.header_menu_wrapper {
  width: 250px;
}

.header_config_version_span {
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.counter {
  &_container {
    display: flex;
    justify-content: space-between;
    min-width: 75px;
  }
}

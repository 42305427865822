.table.ant-table-wrapper {
  height: 100%;

  .ant-spin-nested-loading {
    height: 100%;

    .ant-spin-container {
      height: 100%;
      display: flex;
      flex-flow: column nowrap;

      .ant-table {
        flex: auto;
        overflow: hidden;

        .ant-table-container {
          height: 100%;
          display: flex;
          flex-flow: column nowrap;

          .ant-table-header {
            flex: none;
          }

          .ant-table-body {
            flex: auto;
            overflow: scroll;
          }
        }
      }

      .ant-table-pagination {
        flex: none;
      }
    }
  }
}

@import 'variables';

.button {
  display: flex;
  justify-content: flex-end;
}

.button_wrapper_action {
  width: 100%;
}

.button_save.ant-btn {
  color: $save;
}

.input {
  width: 100%;
}

.prroSettings_form {
  height: calc(100vh - 200px);
  overflow-y: auto;
  overflow-x: hidden;

  .keysField_wrapper {
    .key_item {
      padding: 10px;
      border: 1px dashed lightgray;
    }
  }
}
@import 'variables';

.addButton_wrapper {
  .add_button {
    text-transform: uppercase !important;
    color: $add !important;
    width: 100%;
  }
  :disabled{
    text-transform: uppercase !important;
    width: 100%;
  }
}


.recalculateButton_wrapper {
  .recalculate_button {
    padding: 5px;
    text-transform: uppercase !important;
    min-width: 120px;
  }
  :disabled{
    padding: 5px;
    text-transform: uppercase !important;
    min-width: 120px;
  }
}

.line_through_text {
  text-decoration-line: line-through;
  text-decoration-thickness: 1px;
}

.search_wrapper {
  padding: 5px;

  .search_input {
    margin-bottom: 2px;
    display: block;
  }
  .search_buttons-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .reset_button {
      background: none;
      border: none;
      color: #cdcdcd;
    }
  }
}
.active {
  color: #1890ff !important;
}
.input_wrapper {
  .ant-form-item {
    margin-bottom: 5px !important;
  }
}

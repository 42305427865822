.recalculate_modal{
  .modal_footer{

    padding-top: 20px;
    display: flex;
    justify-content: flex-end;
    .footer_button{
      margin-right: 10px;
    }
  }
}
.addIngredient_form{
  .form_field{
    margin-bottom: 2px !important;
  }
  .rawField_label{
    .rawLabel_icon{
      scale: 90%;
    }
  }
  .form_footer{
    padding-top: 30px;
    display: flex;
    justify-content: flex-end;
    .footer_button{
     margin-right: 10px;
    }
  }
}
@import 'variables';

$formPadding: 24px;

.order-form{
  height: 100%;
  position: relative;

  &_content {
    background-color: $whiteColor;
    height: calc(100% - 213px);
    margin: 0 !important;
    padding: $formPadding;

    & > .ant-col {
      overflow: auto;
      height: 100%;
    }
  }

  &_date-picker {
    min-width: 0 !important;
    width: 100%;
  }

  & .table-body {
    overflow: auto;
    max-height: calc(100vh - 354px);
  }

  &_product-table {
    width: 100%;
    background-color: $whiteColor;
    border: 1px solid $lightGray;

    &-container {
      max-height: calc(100vh - 330px);
    }

    thead {
      th {
        font-weight: 500;
        border: 1px solid $lightGray;
      }
    }
    $columnWidths: 32px, auto, 95px, 86px, 70px, 65px;
        @for $i from 1 through length($columnWidths) {
            tr td:nth-child(#{$i}) {
              width: nth($columnWidths, $i);
            }
          tr th:nth-child(#{$i}) {
            width: nth($columnWidths, $i);
          }
        }

    td {
      padding: 5px;
      border: 1px solid $lightGray;
    }

    & .cell-wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
    }

    .clear-form-margin {
      margin-bottom: 0;
    }
  }

  &_header {
    padding: $formPadding !important;
  }

  &_footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: $formPadding;

    & .buttons {
      &_container {
        display: flex;
        justify-content: space-between;
      }
    }

    &_meta {
      display: flex;
      justify-content: flex-end;
    }
  }
}

.order-header{
  &_title-block {
    display: flex;
    align-items: center;

    & .tag{
      margin-left: 10px;
    }
  }
}

.table_row:hover{
  background-color: lightskyblue !important;
}
.content_center {
  .ant-input {
    text-align: center;
  }
}

// To display labels at the beginning of the container
.ant-col-xs-24 {
  text-align: left !important;
}

.ant-table-cell .ant-tag {
  margin: 0 !important;
}
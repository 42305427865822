@import 'variables';

.mobile_menu_icon.anticon svg {
  font-size: $iconSize;
}

.mobile_menu_icon_drop_down_more_outlined.anticon svg {
  font-size: $iconSize;
  color: $whiteColor;
}

.mobile_menu_language_menu {
  margin-bottom: 5px;
  margin-top: 5px;
}

.mobile_menu_login {
  font-size: $iconSize;
  display: flex;
  justify-content: center;
}

.mobile_menu_dropdown_button {
  color: $whiteColor;
}

.mobile_menu_wrapper {
  display: flex;
  align-items: center;
}

@import 'variables';

.statistic_card_wrapper {
  padding-bottom: 8px;
}

.statistic_card {
  display: flex;
  justify-content: space-between;
}

.statistic_card_span {
  font-size: 15px;
}

.button {
  display: flex;
  justify-content: flex-end;
}

.button_wrapper_action {
  width: 100%;
}

.button_save.ant-btn {
  color: $save;
}

.row_wrapper {
  padding-bottom: 8px;
  padding-left: 15px;
  padding-right: 15px;
}

.accounting-selector {
  &__container {
    margin: 12px 0 12px 12px !important;
    width: 15vw;
  }
}

.table-tags-container {
  display: flex;
  justify-content: flex-end;
  margin: 8px 0;

  .ant-tag {
    font-size: 14px;
    line-height: 25px;
    align-self: center;
    cursor: pointer;

    .anticon {
      margin-left: 6px;
      margin-top: 5px;
    }
  }
}

.accountingPage_header{
  .header_subTitle{
    display: flex;
    align-items: center;
  }

}

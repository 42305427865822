@import 'variables';

.customCloseComponent .ant-modal-close-x {
  display: block;
  width: 100px;
  height: 54px;
  font-size: 16px;
  font-style: normal;
  line-height: 54px;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
}

.antdBody.ant-modal-body {
  padding: 16px;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
  overflow: hidden;
}

.modal.ant-modal {
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  pointer-events: none;
  position: relative;
  top: 30px;
  width: auto;
  max-width: calc(100vw - 32px);
  margin: 0 auto;
  padding: 0 0 24px;
}

.writeOffModalHeight .ant-modal-body {
  height: calc(100vh - 10em);
  overflow: hidden !important;
  top: 0;
}

.zReportsForm_wrapper {
  margin: 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 150px);
  overflow-y: auto;
  overflow-x: hidden !important;

  .form_body {
    .ant-form-item {
      margin-bottom: 10px !important;
    }

    .period_field {
      width: 100%;
    }
  }

  .form_footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    @media (max-width: 1138px) {
      flex-direction: column-reverse;

    }

    .footer_button {
      @media (max-width: 1138px) {
        margin-bottom: 5px;
        width: 100% !important;
      }
    }
  }
}
@import 'variables';

.isWarningRow {
  background-color: $warning;
}

.disabledRowHover .ant-table-tbody > tr.ant-table-row-level-0:hover > td {
  background: unset;
}

.sort td.ant-table-column-sort {
  background: $warning;
}

.hover:hover.ant-table-tbody > tr.ant-table-row:hover > td,
.ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background: $error;
}

.action.ant-table-cell-fix-left,
.ant-table-cell-fix-right {
  position: sticky !important;
  z-index: 2;
 background: $warning;
}

.table.ant-table-wrapper {
  height: 100%;

  .ant-spin-nested-loading {
    height: 100%;

    .ant-spin-container {
      height: 100%;
      display: flex;
      flex-flow: column nowrap;

      .ant-table {
        flex: auto;
        overflow: hidden;

        .ant-table-container {
          height: 100%;
          display: flex;
          flex-flow: column nowrap;

          .ant-table-header {
            flex: none;
          }

          .ant-table-body {
            flex: auto;
            overflow: scroll;
          }
        }
      }

      .ant-table-pagination {
        flex: none;
      }

      .ant-table-tbody > tr.ant-table-row:hover > td,
      .ant-table-tbody > tr > td.ant-table-cell-row-hover {
        background-color: unset;
      }

      .ant-table-cell.ant-table-cell-fix-right.ant-table-cell-fix-right-first {
        background-color: white;
      }
    }
  }
}

.headerStyle.ant-page-header-compact .ant-page-header-heading {
  flex-wrap: wrap;
  display: flex;
  align-items: center;
}

.hideScroll .ant-table-body {
  overflow-x: auto !important;
  overflow-y: auto !important;
}

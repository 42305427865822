@import 'variables';

.expenses {
  color: $expenses !important;
}

.save {
  color: $save !important;
}

.add {
  width: 100%;
  color: $add !important;
}

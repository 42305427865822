.form_label_after .ant-form-item-label > label:after {
  content: '';
}
.ant-table-footer {
  width: 100%;
  .table_footer {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
  }
}

.supplyAdd_table {
  .ant-table {
    height: 85vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
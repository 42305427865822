.noWrap.ant-page-header-compact .ant-page-header-heading {
  flex-wrap: nowrap;
}

.header .ant-page-header-heading-left {
  display: flex;
  margin: 4px 0;
  overflow: hidden;
  align-items: baseline;
}

.arrivalEmptyTable .ant-table .ant-empty-normal {
  height: 20vh;
}

.arrivalTableHeight .ant-table {
  height: 40vh;
}

.StoreBalanceTable .ant-table {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  font-size: 14px;
  background: #fff;
  border-radius: 2px;
  height: 67vh;
}

.prroKeyListItem_wrapper {
  display: flex;
  border: 1px dashed lightgray;
  padding: 5px;
  margin-bottom: 5px;

  .prroKey_body {
    width: 90%;

    .ant-form-item-label > label {
      width: 110px;
    }

    .prroKey_uploader {
      display: block;
      width: 100%;
      max-width: 200px;

      .ant-upload {
        width: 100%;

        .upload_button {
          width: inherit;
        }
      }
    }

    .ant-form-item {
      margin-bottom: 5px !important;
    }

    .key_wrapper {
      padding: 5px 0;
    }
  }

  .prroKey_actions {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    padding: 0 10px;
    min-width: 160px;

    .action_button {
      width: 100%;
      margin-bottom: 5px;
    }
  }
}

.valid {
  border: 1px dashed lightgreen;
}

@media (max-width: 575px) {
  .prroKey_body {
    width: 100% !important;
  }

  .prroKeyListItem_wrapper {
    flex-direction: column;
  }

  .prroKey_actions {
    padding: 0 !important;
  }
}


.listItem_row{
  .title{
    .ant-list-item-meta-title{
      font-weight: 900 !important;
    }
    width: 35%;
    text-align: left;
  }
  .content{
    width: 65%;
    text-align: right;
  }
}

.listItem_column{
  display: flex;
  flex-direction: column;
  .title{
    .ant-list-item-meta-title{
      font-weight: 900 !important;
    }
    width: 100%;
    text-align: left;
    padding: 0 !important;
  }
  .content{
    text-align: left;
    width: 100%;
  }
}
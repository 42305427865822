.price-modal-body .ant-modal-body {
  top: 10 !important;
  overflow: auto;
}

.details-price-body {
  &_wrapper {
    width: 100%;
    overflow: hidden;

    .table-wrapper {
      padding: 5px;
    }
  }
}

.columns {
  &.task_name {
    width: 900px;
  }

  &.task_action {
    width: 100px;
  }
}

.button.ant-btn {
  margin: 3px;

  &.detail_button {
    background-color: #2fa82d;
    border-color: #2fa82d;
  }
}

.form_style {
  margin-bottom: 0;
}

.form_element {
  &.autoComplete {
    width: 100px;
  }

  &.input {
    width: 100%;
  }
}

.columns {
  &.name_column {
    width: 250px;
  }

  &.action {
    width: 75px;
  }

  &.total_amount {
    width: 75px;
  }

  &.stores {
    width: 220px;
  }
}

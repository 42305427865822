.items_table{

  .ant-table-tbody > .ant-table-row:hover {
    background-color: lightskyblue !important
  }
  .ant-table-cell{
    padding: 0 !important;
    margin: 0 !important;
    .ant-form-item{
      margin: 0 !important;
      padding: 0 !important;
      .ant-select-selector {
        border: 0 !important;
      }
      .ant-input{
        border: 0 !important;
      }
      .ant-input-group-addon{
        border: 0 !important;
      }
    }
  }
  .columns {
    &.id_column {
      width: 60px;
    }
    &.item_name {
      max-width: 380px;
    }
    &.balance_column {
      width: 130px;

      .balanceColumn_content{
        display: flex;
        justify-content: space-between;
        font-size: 14px;
       .amount_wrapper{
         width: 67%;
       }
        .unit_wrapper{
          width: 33%;
        }
      }
    }
    &.quantity_column {
      width: 135px;
      .amount_input{
        span:last-child{
          min-width: 45px !important;

        }
      }

    }
    &.action_column {
      width: 80px;
    }
  }
  td.balance_column{
    background-color:#f5f5f5;
  }
  .nameColumn_content{
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    min-height: 25px;

    .arrow_icon{
      font-size: 12px !important;
      padding-right: 10px;
      color: rgba(0, 0, 0, 0.25);
    }
  }
  .quantityColumn_content{
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    width: 135px;
    padding: 5px 0;
    .amount{
      width: 67%;
    }
    .unit{
      min-width: 40px !important;
      width: 33%;
    }
  }
}
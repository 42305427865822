@import 'variables';

.body_wrapper{
  background-color: $whiteColor;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  .form_wrapper{
    width: 50%;
    padding-right: 10px;
  }
  .tabs_wrapper{
    width: 50%;
  }
}

.items_table{
  .ant-table-cell{
    padding: 0 !important;
    margin: 0 !important;
    .ant-form-item{
      margin: 0 !important;
      padding: 0 !important;
      width: inherit !important;
     .ant-select-selector {
       border: 0 !important;
     }
      .ant-input{
        border: 0 !important;
      }
      .ant-input-group-addon{
        border: 0 !important;
      }
    }
  }
  .columns {
    &.id_column {
      width: 60px;
    }
    &.item_name {
      min-width: 200px;
      max-width: 450px;
    }
    &.quantity_column {
      width: 110px;
    }
    &.action_column {
      width: 80px;
    }
  }
}
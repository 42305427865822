@import 'variables';

.card .ant-card-body {
  padding: 10px;
}

.statistic_card_span {
  font-size: 15px;
  color: $greenColor;
}

.title_card_span {
  color: rgba(0, 0, 0, 0.45);
}

.purchaseDetailModalBody .ant-modal-body {
  overflow: auto;
  height: calc(100vh - 6em);
}

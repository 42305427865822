.productHeader_wrapper{
  width: 100%;
  display: flex;
  justify-content: space-between;
  .title_wrapper{
    width: 50%;
  }
  .buttons_wrapper{
    width: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .button{
      margin: 0 10px 0 0;
    }
  }
}
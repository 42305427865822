@import 'variables';

.header_button_refill {
  color: $add !important;
  text-transform: uppercase;
  width: 100%;
}

.header_button_edit.ant-btn > span {
  color: $add;
  text-transform: uppercase;
}

.header_button_edit_disabled .ant-btn[disabled] {
  color: $add;
  text-transform: uppercase;
}

.form.ant-form-item {
  margin-bottom: 0;
}

.header_autocomplete {
  width: 130px !important;
}

.space_header_menu {
  display: flex;
}

.space_header_menu_autocomplete {
  width: 100%;
}

.header_parent_button_block {
  display: flex;
  justify-content: center;
  padding-top: 10px;
}

.header_parent_button_block_button.ant-btn > span {
  color: $add !important;
  text-transform: uppercase;
}

.store_balance_header{
  .subTitle_wrapper{
    width: 290px;
    display: flex;
    justify-content: space-around;
  }

}
.store_balance_table {
  height: calc(100vh - 200px) !important;
}

.add-product {
  &_modal-body {
    // To display labels at the beginning of the container
    & .ant-form-item-label {
      text-align: left;
    }
  }
}

.order-payments {
  &_table {
    max-height: calc(100vh - 300px);
    min-height: 400px;
    .checkId_wrapper:hover{
      cursor: pointer !important;
    }

    .ant-table-body {
      overflow: auto !important;
      max-height: calc(100vh - 350px);
    }

    .ant-table-footer {
      padding-right: 0;
    }

    &-footer {
      display: flex;
      justify-content: space-between;
      font-weight: 600;
      padding-right: -16px;
    }
  }
}

.orderProduct_modal{
  .cancel_btn{
    margin-right: 30px;
  }
}

.orderPayment_modal{
  .cancel_btn{
    margin-right: 30px;
  }
}
.orderPayments_modal{
  .cancel_btn{
    margin-right: 30px;
  }
}
@import 'variables';

.sendButton_wrapper{
  .send_button,
  .send_button:hover,
  .send_button:focus,
  .send_button:active{
    background-color: $success !important;
    border-color: $success !important;
    min-width: 120px;
    text-transform: uppercase !important;
  }
  :disabled{
    text-transform: uppercase !important;
    background-color: #f5f5f5!important;
    border-color: #d9d9d9 !important;
    min-width: 120px;
  }
}

.date-range-filter {

  &_container {
    padding: 8px;
    display: flex;
    border-radius: 8px;
    flex-direction: column;
    width: 200px;
  }

  &_item {
    &.ant-picker {

      border-radius: 8px;
      width: 100%;
  
      &:first-child {
        margin-top: 5px;
      }
      &:nth-child(2) {
        margin-top: 15px;
      }
    }
  }

  &_footer {
    margin-top: 15px;
    display: flex;
    justify-content: space-around;
  }
}
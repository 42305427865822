.profitPage_header{
  .profit_subTitle{
    padding: 2px;
    width: 680px;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .ant-page-header-heading-extra{
    display: flex !important;
    align-items: center !important;
  }
}
.notificationImage .ant-empty-image {
  margin-top: 14%;
}
.notification_page-header {
  background-color: unset !important;
}
.disabledRowHover .ant-table-tbody > tr.ant-table-row-level-0:hover > td {
  background: unset;
}

@keyframes change-color {
  0% {
    background-color: #ffd700;
  }
  100% {
    background-color: #dcdcdc;
  }
}

.showFullTextSelectNotification {
  -webkit-animation: change-color 3s infinite;
  animation-iteration-count: 1;
}

.unreadMessage {
  background-color: #dcdcdc;
}

.readMessage {
  background-color: #fff;
}

.table.ant-table-wrapper {
  height: 100%;

  .ant-spin-nested-loading {
    height: 100%;

    .ant-spin-container {
      height: 100%;
      display: flex;
      flex-flow: column nowrap;

      .ant-table {
        flex: auto;
        overflow: hidden;

        .ant-table-container {
          height: 100%;
          display: flex;
          flex-flow: column nowrap;

          .ant-table-header {
            flex: none;
          }

          .ant-table-body {
            flex: auto;
            overflow: scroll;
          }
        }
      }

      .ant-table-pagination {
        flex: none;
      }
    }
  }
}

.sort td.ant-table-column-sort {
  background-color: #dcdcdc;
}

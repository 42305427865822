@import 'variables';

.treeStyle.ant-tree .ant-tree-treenode {
  display: flex;
  align-items: flex-start;
  padding: 0 0 4px;
  outline: none;
}

.cursorPointer.ant-tree.ant-tree-directory .ant-tree-treenode:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 4px;
  left: 0;
  transition: background-color 0.3s;
  pointer-events: none;
}

.background.ant-tree.ant-tree-directory .ant-tree-treenode-selected:before,
.ant-tree.ant-tree-directory .ant-tree-treenode-selected:hover {
  background: unset;
}

.switchable.ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-switcher {
  display: flex;
  transition: color 0.3s;
  align-items: center;
}

.contentWrapper.ant-tree .ant-tree-node-content-wrapper {
  position: relative;
  z-index: auto;
  min-height: 24px;
  margin: 0;
  padding: 0 4px;
  color: inherit;
  line-height: 24px;
  background: transparent;
  border-radius: 2px;

  //width: 100%;
  transition: all 0.3s, border 0s, line-height 0s, box-shadow 0s;
}

.switcher .ant-tree-switcher {
  position: relative;
  flex: none;
  display: flex;
  align-items: center;
  align-self: stretch;
  width: 24px;
  margin: 0;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.cardHeight .ant-card-body {
  padding: 16px;
}

.parentBlock .ant-tree-list {
  margin: 5px;
}

.categories_wrapper {
  height: 75vh;
  width: 100%;
  background-color: $whiteColor;
}

.categories_title_render_wrapper {
  font-size: 1rem;
  height: 60px;
  padding-top: 15px;
  border: 1px solid $mainColor;
}
.categories_title_render_wrapper:hover{
  background-color: lightskyblue !important;
}

.categories_title_render_children {
  display: flex;
  align-items: center;
  padding: 0 15px;
  justify-content: space-between;
}

.small-text {
  font-size: 14px;

  &._right {
    margin-right: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.categories_dropdown {
  background-color: $whiteColor;
  display: flex;
  flex-direction: column;
  padding: 5px;
}

.button_wrapper {
  display: flex;
  justify-content: flex-end;
  width: 20%;
}

.button_default.ant-btn {
  margin: 3px;

  &.button_add {
    background-color: $greenColor;
    border-color: $greenColor;
  }
}

.icon {
  font-size: 15px;
}

@import 'variables';

.body_wrapper{
  padding: 24px;
  background-color: $whiteColor;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  .form_wrapper{
    width: 50%;
  }
  .tabs_wrapper{
    width: 50%;
  }
}
.incasationModalBody .ant-modal-body {
  overflow: auto;
  height: calc(100vh - 25em);
}

.listItem_title {
  font-weight: 500;
}

.signature_wrapper {
  width: 375px;
  height: 210px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

@import 'variables';

.header_wrapper{
  display: flex;
  justify-content: space-between;
  align-items: center;
  .header_title{
    display: flex;
    align-items: center;
    .status_tag{
      margin-left: 5px;
    }
  }
}
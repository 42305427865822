@import 'variables';

.downloadButton_wrapper{
  .download_button,
  .download_button:hover,
  .download_button:focus,
  .download_button:active{
    text-transform: uppercase !important;
    background-color: $success !important;
    border-color: $success !important;
  }
  :disabled{
    background-color: #f5f5f5!important;
    border-color: #d9d9d9 !important;
    text-transform: uppercase !important;
  }
}

.zReport_body {
  display: flex;
  flex-direction: row;

  .zReportsForm_wrapper {
    width: 25%;
  }

  .zReportTable_wrapper {
    width: 75%;
  }
}
@import 'variables';

.button {
  display: flex;
  justify-content: flex-end;
}

.button_wrapper_action {
  width: 100%;
}

.button_save.ant-btn {
  color: $save;
}

.kpi_card.ant-card-bordered {
  border-radius: 5px;
  box-shadow: 0 4px 4px #9f9f9f;
  min-width: 15px;

  &.card .ant-card-body {
    padding: 0.2em;
    min-width: 5em;
  }
}

.kpi_card .ant-typography {
  margin-bottom: 0;
}

.kpi_card .card-footer {
  display: flex;
  flex-direction: column;
}

.card-text {
  &.general {
    font-size: 15px;
    margin: 0;
    display: flex;
    justify-content: space-between;
  }

  &.bold {
    font-weight: bold;
  }
}

.footer_text {
  color: #a1a1a1;
  margin-bottom: 0;
}

li.li_element::marker {
  color: #9f9f9f;
  font-size: 15px;
}

.placeholder-field {
  background-color: #fff;
  margin: 10px;
  padding: 10px;
}

.text {
  padding: 0 10px 0 3px;
}

.title {
  padding: 0 15px 0 15px;
  font-family: Monaco, sans-serif;
}

.card_header {
  color: #9f9f9f;
}

@import 'variables';

.store_balance_page_header_extra_button {
  width: 100%;
}

.store_balance_page_header_subtitle {
  width: 350px;
}

.store_balance_page_header_subtitle_form.ant-select-disabled.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  width: 160px;
}

.store_balance_page_header_extra_button_default.ant-btn > span {
  color: $save;
}

.form.ant-form-item {
  margin-bottom: 5px;
}

.comment_wrapper {
  padding-left: 24px;
}

.store_balance_edit {
  position: relative;
  height: 100%;

  &_table {
    height: calc(100vh - 245px) !important;
  }

  &_footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 10px;
    padding: 0 10px;
  }

  &_text {
    margin-left: 5px;
  }
}

.informationModal_wrapper{
  .informationModal_layout{
    width: 100% !important;
    height: calc(100vh - 50px);
    .header{
      display: flex;
      align-items: center;
      height: 35px !important;
      padding: 0 !important;
      margin: 0 !important;
      font-size: 16px;
      font-weight: 500;
      background-color: white;
      border-bottom: 1px dashed lightgray;
div{
  margin-bottom: 11px !important;
}
    }
.body_wrapper{
  display: flex;
  flex-direction: row;
  padding: 5px !important;
  background-color: white;
  .metadata_wrapper{
    width: 20% !important;
    border-right: 1px dashed lightgray;
  }
  .hidden {
    display: none !important;
  }
  .content_wrapper{
    background-color: white !important;
    width: 80%;
    padding: 5px 5px 5px 0;
    .mainContent_wrapper{
      padding: 5px;
    }

    .actions_wrapper{
      display: flex;
      align-items: center;
      .metadataVisible_icon{
      font-size: 22px;
        margin-right: 10px;
      }
    }

  }
}
    .footer {
      background-color: white;
      padding: 5px !important;
      border-top: 1px dashed lightgray;
    }
  }

}
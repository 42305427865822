.table.ant-table-wrapper {
  height: 100%;

  .ant-table-tbody > .ant-table-row:hover > td {
    background-color: lightskyblue !important;
  }
  .ant-table-tbody > .ant-table-row:hover > td.actions-column {
    background-color: lightskyblue !important;
  }

  .ant-spin-nested-loading {
    height: 100%;

    .ant-spin-container {
      height: 100%;
      display: flex;
      flex-flow: column nowrap;

      .ant-table {
        flex: auto;
        overflow: hidden;

        .ant-table-container {
          height: 100%;
          display: flex;
          flex-flow: column nowrap;

          .ant-table-thead {
            th {
              font-size: 12px !important;
              padding: 2px 6px 2px 6px !important;
            }
          }

          .ant-table-tbody {
            td {
              font-size: 12px !important;
              padding: 2px !important;
            }
          }

          .ant-table-header {
            flex: none;
            table-layout: fixed !important;
          }

          .ant-table-body {
            flex: auto;
            overflow: scroll;
          }
        }
      }

      .ant-table-pagination {
        flex: none;
      }
    }
  }
}

.table-wrapper table {
  table-layout: fixed !important; /* rewrite inline styles */
  width: 100% !important;
}

.hideScroll .ant-table-body {
  overflow-x: auto !important;
  overflow-y: auto !important;
}

.background-important .ant-table-cell-fix-left,
.ant-table-cell-fix-right {
  position: sticky !important;
  z-index: 2;
  background: #fff !important;
}

td.ant-table-column-sort {
  background-color: transparent !important;
}

@import 'variables';

.rowHeight .ant-table-tbody > tr > td {
  height: 5px;
  padding: 4px;
}

.refillModalBody .ant-modal-body {
  overflow: auto;
  height: calc(100vh - 6em);
}

.isError {
  background-color: $error;
}

.isWarning {
  & .ant-table-cell {
    background-color: $warning !important;
  }
}

.isSortError td.ant-table-column-sort {
  background-color: $error;
}

.isSortWarning td.ant-table-column-sort {
  background-color: $warning;
}

.tablePurchase.ant-table-wrapper {
  height: 100%;

  .ant-spin-nested-loading {
    height: 100%;

    .ant-spin-container {
      height: 100%;
      display: flex;
      flex-flow: column nowrap;

      .ant-table {
        flex: auto;
        overflow: hidden;

        .ant-table-container {
          height: 100%;
          display: flex;
          flex-flow: column nowrap;

          .ant-table-header {
            flex: none;
          }

          .ant-table-body {
            flex: auto;
            overflow: scroll;
          }
        }
      }

      .ant-table-pagination {
        flex: none;
      }
    }
  }
}

.customCloseComponent .ant-modal-close-x {
  display: block;
  width: 100px;
  height: 54px;
  font-size: 16px;
  font-style: normal;
  line-height: 54px;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
}

.customCloseComponent .ant-modal-close-x {
  display: block;
  width: 100px;
  height: 54px;
  font-size: 16px;
  font-style: normal;
  line-height: 54px;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
}

.antdBody .ant-modal-body {
  padding: 16px;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
}

.modal.ant-modal {
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  pointer-events: none;
  position: relative;
  top: 30px;
  width: auto;
  max-width: calc(100vw - 32px);
  margin: 0 auto;
  padding: 0 0 24px;
}

.inventory-body {
  &_details-header {
    display: flex;
    align-items: center;

    .switch_wrapper {
      margin-left: 5px !important;
    }
  }
}

.inventory-details {
  &_col {
    border: 1px dashed #f0f0f0;
  }
}

@import 'variables';

.button {
  text-transform: uppercase;

  &.add_button {
    color: $add;
  }
}
.refillInformation_wrapper{
  .download_btn{
    margin: 5px 0;
  }
}

@import 'variables';

.items_tabs{
  height: 70vh;
  .add_button{
    color: $add;
  }
  .ant-tabs-nav{
    margin-bottom: 5px !important;
  }
}
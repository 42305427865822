.column {
  &.actions {
    width: 7%;
  }

  &.update_date {
    width: 20%;
  }

  &.create_date {
    width: 20%;
  }

  &.status {
    width: 20%;
  }

  &.stores {
    width: 20%;
  }
}

.button {
  margin: 3px;

  &.detail_button {
    background: #2fa82d;
    border-color: #2fa82d;
  }
}

.line_through_text {
  text-decoration-line: line-through;
  text-decoration-thickness: 1px;
}

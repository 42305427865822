@import 'variables';

.list_component {
  &.wrapper {
    border: 1px dashed #f0f0f0;
  }
}

.button {
  text-transform: uppercase;

  &.add_button {
    color: $add;
  }

  &.danger_button {
    width: 100%;
  }
}
